.custom-node__header {
    padding: 8px 10px;
}

.custom-node__logo {
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
}

.custom-node__header img {
    width: 30px;
    height: 30px;
}

.custom-node__header__text {
    text-align: center;
    font-weight: bold;
    font-size: large;
}

.custom-node__header__text__medium {
    text-align: center;
    font-weight: bold;
    font-size: medium;
}

.custom-node__body {
    padding: 10px;
}

.custom-node__handle {
    padding: 8px 10px;
    position: relative;
}

.custom-node__select {

}

.custom-node__select select {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
}

.custom-node__button {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
}

.custom-node__text {
    /*display: flex;*/
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.custom-node__text label {

}

.custom-node__text input {
    width: 100%;
}

.add-node {
    padding: 8px 10px;
}

.add-node img {
    width: 50px;
    height: 50px;
}

.add-node__text {
    text-align: center;
    font-weight: bold;
    font-size: large;
}
