.react-flow__node-shopify {
    font-size: 10px;
    width: 180px;
    /*background: #4f954c;*/
    background: #5a863e;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 20px;
}

.react-flow__node-shopify .react-flow__handle {
    top: 24px;
    left: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #008060;
}

.react-flow__node-shopify div {
    color: #e2e8f0;
}

.react-flow__node-mirakl {
    font-size: 10px;
    width: 180px;
    background: #00264b;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 20px;
}

.react-flow__node-mirakl .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
}

.react-flow__node-mirakl div {
    color: #e2e8f0;
}


.react-flow__node-amazon {
    font-size: 10px;
    width: 180px;
    background: #ff9900;
    color: #222;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
    border-radius: 20px;
}

.react-flow__node-amazon .react-flow__handle {
    top: 24px;
    right: -15px;
    width: 6px;
    height: 10px;
    border-radius: 2px;
    background-color: #778899;
}

.react-flow__node-amazon div {
    color: #e2e8f0;
}

.react-flow__node.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.react-flow__node.annotation {
    border-radius: 0;
    text-align: left;
    background: white;
    border: none;
    line-height: 1.4;
    width: 225px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
}

.react-flow__node.annotation .react-flow__handle {
    display: none;
}
