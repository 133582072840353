table {
    border-spacing: 0;
    border: 1px solid #ededed;
    border-collapse: separate; /* Use 'separate' for curved borders */
    border-radius: 10px; /* Adjust the radius value as needed */
    overflow: hidden; /* Ensure content doesn't extend beyond the rounded border */
    width: 100%;
}

table tr:hover {
    background-color: #e0e0e0;
}

table tr:nth-child(2n):hover {
    background-color: #e0e0e0;
}

table tr:nth-child(2n) {
    background-color: rgba(44, 44, 44, 0.03); /* Change the background color on hover */
}

table tr:last-child td {
    border-bottom: 0
}

table td, table th {
    margin: 0;
    padding: .7rem;
    border-bottom: 1px solid #ededed;
    border-right: 1px solid #ededed;
    position: relative
}

table td:last-child, table th:last-child {
    border-right: 0
}

table th:before {
    position: absolute;
    right: 15px;
    top: 16px;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent
}

table th.sort-asc:before {
    border-bottom: 5px solid #22543d
}

table th.sort-desc:before {
    border-top: 5px solid #22543d
}
